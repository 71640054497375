import xss from 'xss';
class Xss {
  option = {
    stripIgnoreTag: true,
    whiteList: {
      img: ['src'],
      br: [],
    },
    css: false,
  }
  officialUserOption = {
    stripIgnoreTag: true,
    whiteList: {
      br: [],
      img: ['src'],
      a: ['href', 'target', 'rel'],
      p: ['style'],
      span: ['style'],
      div: ['style'],
    },
    css: {
      whiteList: {
        color: true  // 'color'プロパティを許可
      }
    },
  };
  process = (text: string): string => {
    const convertText = xss(text, this.option);
    return convertText;
  }
  officialUserProcess = (text: string): string => {
    const convertText = xss(text, this.officialUserOption);
    return convertText;
  };
}
export default new Xss;
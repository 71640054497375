import core from '@/admin/core';

// interface
export interface Reaction {
  createDate: number;
  reactionId: string;
  reactionName: string;
  icon: string;
  isDeleted: boolean;
}

export interface ReactionProps {
  reactionName: string;
  icon: string;
}

// method
export const getReactions = async (): Promise<Reaction[]> => {
  try {
    const result = await core.httpClient.get('/admin/public/reactions');
    return result.data as Reaction[];
  } catch {
    return [] as Reaction[];
  }
};

export const createReaction = async (props: ReactionProps) => {
  await core.httpClient.post('/admin/public/reactions', props);
};

export const editReaction = async (props: ReactionProps, reactionId: string) => {
  await core.httpClient.put(`/admin/public/reactions/${reactionId}`, props);
};














































































































































































































































































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { useChatRoomUser, useMessages } from '@/composition/myTalk';
import { useNotification } from '@/composition/notification';
import { useReactions } from '@/composition/reactions';
import { downloadCsv } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';
import xss from '@/composition/xss';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'TalkRoomPost',
  components: {
    FcImage,
    FcStaticImage,
    FcWysiwyg,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    chatroomId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('mytalks'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const reactions = useReactions();
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();
    const formData: Ref<{
      replyMessage: { chatMessageId: string; body: string } | null;
      body: string;
      image: string;
    } | null> = ref(null);
    const reactionData: Ref<{
      chatMessageId: string;
      reactions: {
        reactionId: string;
        icon: string;
        isSelected: boolean;
        isAddedReaction: boolean;
      }[];
    } | null> = ref(null);
    const isActivePostDialog = ref(false);
    const isActiveReactionDialog = ref(false);
    const isPosting = ref(false);
    const isDeleting = ref(false);

    const isActiveUpdateIsPendingActionDialog = ref(false);
    const isUpdatingIsPendingAction = ref(false);

    const chatRoomUser = useChatRoomUser(props.chatroomId, props.userId);
    chatRoomUser.getChatRoomUser();
    const messages = useMessages(props.chatroomId, props.userId);

    const pageTitle = computed(() => messages.chatRoom.title);

    const headers = [
      { text: '投稿者', value: 'postedUsername', width: '200px', align: 'start' },
      { text: '投稿内容', value: 'body' },
      { text: '投稿画像', value: 'image' },
      { text: '投稿日時', value: 'postedDate.dateTime', width: '150px' },
      { text: '', value: 'actions', width: '200px', sortable: false, align: 'end' },
    ];
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // 投稿
    const createPost = (chatMessageId?: string, replyMessage?: string) => {
      formData.value = {
        replyMessage: chatMessageId && replyMessage ? { chatMessageId: chatMessageId, body: replyMessage } : null,
        body: '',
        image: '',
      };
      isActivePostDialog.value = true;
    };
    const closePostDialog = () => {
      formData.value = null;
      isActivePostDialog.value = false;
    };
    const post = async () => {
      if (!(await confirmDialog('本当に投稿を保存しますか？'))) return;
      if (!formData.value) return;
      isPosting.value = true;
      try {
        const response = await messages.post(formData.value);
        if (response?.type === 'warning') notification.warning(response.message);
        else if (response?.type === 'success') notification.notify(response.message);
        closePostDialog();
        await messages.getMessages();
        await chatRoomUser.getChatRoomUser();
      } catch (error) {
        notification.error(error);
      } finally {
        isPosting.value = false;
      }
    };
    // 投稿：返信
    const replyMessage = async (chatMessageId: string, replyMessage: string) => {
      createPost(chatMessageId, replyMessage);
    };
    // 投稿：削除
    const deleteMessage = async (chatMessageId: string, name: string, date: string) => {
      if (!(await confirmDialog(`この投稿を本当に削除しますか？\n投稿者：${name}\n投稿日時：${date}`))) return;
      isDeleting.value = true;
      try {
        await messages.deleteMessage(chatMessageId);
        await messages.getMessages();
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
      isDeleting.value = false;
    };

    // リアクション
    const openReactionDialog = async (chatMessageId: string, chatMessageReactions?: string[]) => {
      const data = reactions.reactions.map((reaction) => {
        const isAddedReaction = chatMessageReactions ? chatMessageReactions.includes(reaction.reactionId) : false;
        return {
          reactionId: reaction.reactionId,
          icon: reaction.icon,
          isSelected: isAddedReaction,
          isAddedReaction,
        };
      });
      reactionData.value = { chatMessageId, reactions: data };
      isActiveReactionDialog.value = true;
    };
    const closeReactionDialog = () => {
      isActiveReactionDialog.value = false;
      reactionData.value = null;
    };
    const setReactions = async () => {
      if (!(await confirmDialog('本当にリアクションを保存しますか？'))) return;
      if (!reactionData.value) return;
      isPosting.value = true;
      try {
        await messages.setReactions(reactionData.value);
        await messages.getMessages();
        closeReactionDialog();
        notification.notify('保存しました。');
      } catch (error) {
        notification.error(error);
      }
      isPosting.value = false;
    };

    // 未対応フラグ更新
    const openUpdateIsPendingActionDialog = async () => {
      isActiveUpdateIsPendingActionDialog.value = true;
    };
    const closeUpdateIsPendingActionDialog = () => {
      isActiveUpdateIsPendingActionDialog.value = false;
    };
    const updateIsPendingAction = async () => {
      isUpdatingIsPendingAction.value = true;
      try {
        await chatRoomUser.updateIsPendingAction(!chatRoomUser.isPendingAction.value);
        notification.notify('更新しました。');
      } catch (e) {
        notification.error(e);
      } finally {
        isUpdatingIsPendingAction.value = false;
        isActiveUpdateIsPendingActionDialog.value = false;
      }
    };

    // csvダウンロードボタン
    const download = async () => {
      const csvHeaders: DataTableHeader[] = Object.entries(messages.csvLabels).map(([key, value]) => ({
        text: value,
        value: key,
      }));
      const csvItems = messages.getCsvItems();
      downloadCsv(csvHeaders, csvItems, `mytalk-posts-${messages.chatRoomId}.csv`);
    };

    // もっと読み込む
    const getNextMessages = async () => {
      try {
        await messages.getMessages(true);
      } catch (error) {
        notification.error(error);
      }
    };

    return {
      pageTitle,
      myRoleSettings,
      xss,
      reactions,
      wysiwygSetting,
      formData,
      reactionData,
      isActivePostDialog,
      isActiveReactionDialog,
      isPosting,
      isDeleting,
      isActiveUpdateIsPendingActionDialog,
      openUpdateIsPendingActionDialog,
      closeUpdateIsPendingActionDialog,
      updateIsPendingAction,
      chatRoomUser,
      messages,
      headers,
      createPost,
      closePostDialog,
      post,
      replyMessage,
      deleteMessage,
      openReactionDialog,
      closeReactionDialog,
      setReactions,
      download,
      getNextMessages,
      tableHeight,
    };
  },
});

import { Ref, ref } from '@vue/composition-api';
import { Reaction, ReactionProps, getReactions, createReaction, editReaction } from '@/admin/reactions';

export class Reactions {
  private _isLoading = ref(false);
  private _reactions: Ref<Reaction[]> = ref([]);

  get isLoading() {
    return this._isLoading.value;
  }
  get reactions() {
    return this._reactions.value;
  }

  constructor() {
    this.getReactions();
  }

  getReactions = async () => {
    this._isLoading.value = true;
    this._reactions.value = await getReactions()
    // isDeleted:trueのもの返って来なそうなのであとで確認
      .then((reactions) =>
        reactions.filter((reaction) => !reaction.isDeleted).sort((a, b) => a.createDate - b.createDate)
      )
      .finally(() => (this._isLoading.value = false));
  };

  getReactionIcon = (reactionId: string) => {
    return this._reactions.value.find((user) => user.reactionId === reactionId)?.icon || '';
  };

  createReaction = async (props: ReactionProps) => {
    await createReaction(props);
  };

  editReaction = async (props: ReactionProps, reactionId: string) => {
    await editReaction(props, reactionId);
  };
}

export const useReactions = () => {
  const reactions = new Reactions();
  return reactions;
};
